// icons taken from ../../../asset/images/icons/
// NOTE: icons should have the same width, height and viewport size 24px!!
// icons fill or stroke should be with preporty 'currentColor'

export enum EIcon {
  ACCOUNT = 'account',
  ADDING_TO_CART = 'adding_to_cart',
  ADDED_TO_CART = 'added_to_cart',
  AMEX = 'amex',
  ARROW_RIGHT = 'arrow_right',
  ARROW_LEFT = 'arrow_left',
  ARROW_DOWN = 'arrow_down',
  ARROW_UP = 'arrow_up',
  AWARD_PERSONAL = 'award_personal',
  BOX_VIEW = 'box_view',
  CART = 'cart',
  CART_FILL = 'cart_fill',
  CHAT = 'chat',
  CHECKED = 'checked',
  CHECKED_WITH_LAYER = 'checked-with-layer',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  CHEVRON_UP = 'chevron_up',
  CLOCK = 'clock',
  CLOCK_ICON = 'clock_icon',
  CLOSE = 'close',
  DATE = 'date',
  DIRECT_FROM_MANUFACUTRER = 'direct-from-manufacturer',
  DISCOVER = 'discover',
  EMPTY_CART = 'empty-cart',
  ERROR = 'error',
  EYE_ICON = 'eye_icon',
  FACEBOOK = 'facebook',
  FAST = 'fast',
  FAST_SHIPMENT = 'fast_shipment',
  FILTER = 'filter',
  GIFT_CARD = 'gift_card',
  GRATIS_RETOURE = 'gratis-retoure',
  HAND = 'hand',
  HEART = 'heart',
  HEART_FILLED = 'heart-filled',
  HOME = 'home',
  INFO = 'info',
  INSTAGRAM = 'instagram',
  LEGERO = 'legero',
  LETTER = 'letter',
  LIST_ICON = 'list_icon',
  LOCATION = 'location',
  LOCATION_FULL = 'location_full',
  LOCK = 'lock',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MAIL = 'mail',
  MASTERCARD = 'mastercard',
  MENU = 'menu',
  MINUS = 'minus',
  MORE_ACTION = 'more_action',
  NOTIFICATION = 'notification',
  PHONE = 'phone',
  PACKAGE = 'package',
  PIN = 'pin',
  PINTEREST = 'pinterest',
  PINTEREST_COLOR = 'pinterest-color',
  PLUS = 'plus',
  QUESTION_MARK = 'question_mark',
  RETURN = 'return',
  ROTATE = 'rotate',
  STAR = 'star',
  SEARCH = 'search',
  SECURITY = 'security',
  SHIELD = 'shield',
  SHIPPING = 'shipping',
  SORT = 'sort',
  SUPERFIT = 'superfit',
  THINK = 'think',
  THREE_DOTS_VERTICAL = 'three-dots-vertical',
  TWITTER = 'twitter',
  VERIFIED = 'verified',
  VISA = 'visa',
  YOUTUBE = 'youtube',
}
